<template>
  <huboo-page>
    <huboo-page-heading :heading="$t('billing.storageBilling') | title"></huboo-page-heading>

    <huboo-table
      @row-clicked="onSelected"
      @search="handleSearch"
      @filter="handleFilter"
      @update:options="handleUpdateOptions"
      :filters="filters"
      :headers="headers"
      :items="items"
      item-class="class"
      v-bind="options"
      clickable
      :server-items-length="serverItemsLength"
      :loading="loading"
    >
      <template #prepend v-if="totalSum">
        <span class="black--text text-h6 font-weight-black">
          {{ $t('common.total') + ': ' + totalSum + ' ' + currency }}
        </span>
      </template>

      <template #actions>
        <export-button
          v-if="exportUrl"
          :file-name="exportName"
          :export-url="exportUrl"
          :client="box.hubooBox.toString()"
        />
      </template>

      <template v-slot:filter>
        <huboo-date-range-picker
          @update="updateDateRange"
          modal-width="300px"
        ></huboo-date-range-picker>
      </template>
    </huboo-table>

    <storage-billing-modal
      :headers="headersObjectified"
      v-model="modalOpen"
      :selected="selected"
    ></storage-billing-modal>
  </huboo-page>
</template>

<script>
import { title } from '@/utilities/filters'
import { objectify } from '@/utilities/functions'
import StorageBillingModal from '@/views/pages/billing/StorageBillingModal'
import ClientMixin from '@/mixins/client'
import ExportButton from '@/components/pages/ExportButton.vue'

export default {
  name: 'StorageBilling',
  mixins: [ClientMixin],
  components: {
    'storage-billing-modal': StorageBillingModal,
    'export-button': ExportButton,
  },
  data: () => ({
    options: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    },
    modalOpen: false,
    search: '',
    selected: null,
  }),
  computed: {
    exportUrl() {
      return this.storageBilling?.exportUrl
    },
    exportName() {
      return this.$options.name
    },
    headers() {
      return [
        { text: title(this.$t('billing.billingDate')), sortable: true, value: 'createdAt' },
        { text: title(this.$t('common.description')), sortable: true, value: 'description' },
        { text: title(this.$t('billing.itemsStored')), sortable: true, value: 'quantity' },
        { text: title(this.$t('billing.originRegion')), sortable: true, value: 'originRegion' },
        { text: title(this.$t('common.total')), sortable: true, value: 'total' },
      ]
    },
    headersObjectified() {
      return objectify(this.headers, 'value')
    },
    storageBilling() {
      return this.$store.getters['billing/getStorageBilling']
    },
    items() {
      return this.storageBilling.data.map(bh => {
        const v = { ...bh }
        v.createdAt = this.formatDate(v.createdAt)
        return v
      })
    },
    totalSum() {
      return this.storageBilling?.totalSum
    },
    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: this.url,
      })
    },
    meta() {
      return this.storageBilling?.meta || { total: this.items.length }
    },
    url() {
      return '/huboo-box/' + this.box?.hubooBox + '/billing-storage-headers'
    },
  },
  watch: {
    options: {
      handler() {
        this.fetch()
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  methods: {
    fetch() {
      if (!this.loading) {
        const { options, orderBy, search, dateRange } = this
        const { page, itemsPerPage } = options
        const params = {}
        if (page) params.page = page
        if (itemsPerPage) params.itemsPerPage = itemsPerPage
        if (search) params.query = search
        if (dateRange.length === 2) params.updated_at = dateRange?.join(',')
        if (orderBy) params.orderBy = orderBy
        this.$store.dispatch('billing/fetchStorageBilling', { params, url: this.url })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
