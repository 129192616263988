<template>
  <huboo-full-screen-dialog
    :value="value"
    @input="$emit('input', $event)"
    :title="$t('billing.storageBilling') | title"
  >
    <huboo-list
      max-width="600px"
      class=" pa-4 mx-auto text-center"
      clickable
      @clicked="handleItemClicked"
      :items="items"
    ></huboo-list>
    <huboo-modal
      v-for="(item, index) in items"
      :key="'editModal' + index"
      maxWidth="600px"
      v-model="editModals[item.name]"
    >
      <v-card>
        <huboo-form
          :title="$t('common.edit') | title"
          @submit="onSubmit"
          :loading="loadingForm"
          :fields="[item]"
          v-if="editModals[item.name]"
        ></huboo-form>
      </v-card>
    </huboo-modal>
  </huboo-full-screen-dialog>
</template>

<script>
import { title } from '@/utilities/filters'

const FIELDS = ['createdAt', 'description', 'quantity', 'originRegion', 'total']

export default {
  name: 'StorageBillingModal',
  components: {},
  props: {
    selected: Object,
    headers: Object,
    value: Boolean,
  },
  data: () => ({
    editModals: FIELDS.reduce((a, v) => {
      a[v] = false
      return a
    }, {}),
  }),
  computed: {
    fields() {
      return {
        createdAt: { label: title(this.$t('billing.billingDate')) },
        description: { label: title(this.$t('common.description')) },
        quantity: { label: title(this.$t('billing.itemsStored')) },
        originRegion: { label: title(this.$t('billing.originRegion')) },
        total: { label: title(this.$t('common.total')) },
      }
    },
    items() {
      return FIELDS.map(f => ({ ...this.fields[f], name: f })).map(f => ({
        ...f,
        errorMessages: this.$store.getters['core/validationError'](f.name),
        // TODO: disable global readonly attribute when editing allowed and
        // place in individual items above , e.g., id, hubooBox, etc.
        readonly: true,
        text: this.selected?.[f.name],
        title: f.label,
        value: Object.prototype.hasOwnProperty.call(f, 'value') || this.selected?.[f.name],
      }))
    },
    loadingForm() {
      return false
    },
  },
  watch: {},
  created() {},
  methods: {
    handleItemClicked() {
      // this.editModals[e.name] = true
    },
    // onSubmit(e) {
    //   console.log('submitting...', e)
    // },
  },
}
</script>

<style lang="scss" scoped></style>
