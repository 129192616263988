<template>
  <v-btn
    @click="exportToCsv"
    :disabled="disabled"
    :title="$t('common.exportToCsvFile')"
    color="primary"
    >{{ $t('common.exportToCsv') }}</v-btn
  >
</template>

<script>
import { format } from 'date-fns'
import { downloadAjax } from '@/boot/api'

export default {
  name: 'ExportButton',
  props: {
    fileName: { type: String, required: true },
    exportUrl: { type: String, required: true },
    client: { type: String, required: false },
    disabled: { type: Boolean, required: false },
  },

  computed: {
    exportFilename() {
      const formattedDate = format(new Date(), 'dd-MM-yyyy_HH:mm:ss')
      const filename = this.fileName
      const box = this.client
      const extension = '.csv'
      if (this.client) {
        return filename + '_' + box + '_' + formattedDate + extension
      } else return filename + '_' + formattedDate + extension
    },
  },
  methods: {
    exportToCsv() {
      downloadAjax({ filename: this.exportFilename, url: this.exportUrl })
    },
  },
}
</script>
