import { format } from 'date-fns'
import snakeCase from 'lodash/snakeCase'

export const formatDate = (context, d, f = 'dd/MM/yyyy HH:mm:ss') => {
  const date = Date.parse(d)
  if (!date) return
  return format(date, f, { locale: context.$env.locale })
}

export const formatPrice = (context, d, decimalPlaces = 2) => {
  return d.toFixed(decimalPlaces)
}

export const handleSearch = (context, e) => {
  context.search = e
  if (context.options?.page !== 1) context.options.page = 1
  context.fetch()
}

export const orderBy = context => {
  let ob = snakeCase(context.options?.sortBy[0])
  if (context.options?.sortDesc?.[0]) ob += '_desc'
  return ob
}
