import { formatDate, handleSearch, orderBy } from '@/views/pages/billing/billingViewHelpers'
import { goTo } from '@/utilities/helpers/router'
import Vue from 'vue'

const ClientMixin = {
  computed: {
    box() {
      return this.$store.getters['billing/getSelectedBox']
    },
    currency() {
      return this.box?.currency
    },
    dateRange() {
      return this.$store.getters['billing/getDateRange']
    },
    exportName() {
      return this.$options.name
    },
    filters() {
      // TODO: Activate when filtering available via the API
      return null
    },
    isAdmin() {
      return this.$store.getters['core/getIsAdmin']
    },
    loading() {
      return this.$store.getters['core/apiEndpointIsLoading']({
        method: 'GET',
        url: this.url,
      })
    },
    orderBy() {
      return orderBy(this)
    },
    serverItemsLength() {
      return this.meta?.total || 0
    },
  },
  methods: {
    descriptionHasError(d) {
      return d.indexOf('Error Billing Order') !== -1
    },

    formatDate(d) {
      return formatDate(this, d, 'dd/MM/yyyy')
    },

    formatPrice(d) {
      return d.toFixed(2)
    },
    goTo,
    handleFilter(e) {
      // TODO: implement filtering
      console.debug('filtering...', e)
    },
    handleSearch(e) {
      return handleSearch(this, e)
    },
    handleUpdateOptions(e) {
      Vue.set(this, 'options', e)
    },

    onSelected(v) {
      this.selected = v
      this.modalOpen = true
    },

    updateDateRange(dateRange) {
      this.handleUpdateOptions({ ...this.options, dateRange, page: 1 })
    },
  },
}

export default ClientMixin
